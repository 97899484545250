import React from "react"
import { Button } from "react-bootstrap"
import { connect } from "react-redux"
import { removeCartItemFromCart } from "../actions"

class CartItem extends React.Component {
  render() {
    const { cart_item } = this.props;
    const isSerumTwo = cart_item.name === "Serum 2";

    return (
      <tr>
        <td width="75" className="d-none d-sm-table-cell">
          <img className="cart-item-img" width="75" src={cart_item.image} />
        </td>
        <td>
          <h4 className="item-name">{cart_item.name}</h4>
          <span>{cart_item.short_description}</span>
          {isSerumTwo && (
            <div className="m-3 s2-cart-warning p-3">
              <strong>Important:</strong> Do not buy Serum 2 if you own Serum 1. It is a free upgrade from Serum 1.
            </div>
          )}
        </td>
        <td width="75">
          <div className="item-price text-center">
            <h5>${cart_item.price}</h5>
          </div>

          <div className="d-flex justify-content-center">
            <a
              className="remove-button"
              variant=""
              onClick={() => this.props.dispatch(removeCartItemFromCart(cart_item.id))}
              >
              <i className="fa fa-minus"></i> Remove
            </a>
          </div>
        </td>
      </tr>
    )
  }
}

const mapStateToProps = state => ({
  cart_items: state.cart_items
})

export default connect(mapStateToProps)(CartItem);
