import React from "react"
import { connect } from "react-redux"
import { Alert, Table, Button, Row, Col } from "react-bootstrap"

import { getCartTotal } from "../actions"
import SerumUpsells from "components/SerumUpsells"
import UpsellProducts from "components/UpsellProducts"
import LoadingSpinner from "components/LoadingSpinner"
import CartItem from "components/CartItem"
import PaymentOptions from "components/PaymentOptions"

const CheckoutCart = ({
  stripeKey,
  checkoutImage,
  userEmail,
  userSignedIn,
  authenticityToken,
  dispatch,
  cart_items,
  cart_total,
  cart_errors,
  serumOrSerumPresets,
  paypalClientId,
  userId
}) => {
  return (
    <React.Fragment>
      <div>
        <h4>Your Cart</h4>
      </div>
      <div className="content-box">
        {cart_items.length == 0 &&
          <LoadingSpinner message="Loading Cart..." />
        }

        <Table variant="dark" className="cart-table" hover={false}>
          <tbody>
            {cart_items.map((cart_item) => (
              <React.Fragment key={cart_item.id}>
                <CartItem
                  key={cart_item.id}
                  cart_item={cart_item}
                />
              </React.Fragment>
            ))}

          </tbody>
        </Table>

        {cart_errors.map((cart_error, id) => (
          <Alert key={id} dismissible variant='danger'>
            <p>{cart_error.message}</p>
          </Alert>
        ))}

        <Row className="checkout-row">
          {userSignedIn ? (
            cart_total == "$0.00" || cart_items.length == 0 ? (
              <Col sm className="d-flex justify-content-center align-self-center">
                <Button href="/shopping_cart/empty_sale" className="btn-primary">
                  Complete Order
                </Button>
              </Col>
            ) : (
              <PaymentOptions stripeKey={stripeKey} paypalClientId={paypalClientId} userId={userId} />
            )
          ) : (
            <React.Fragment>
              <Col className="checkout-logged-out justify-content-center align-self-center">
                <h4 className="justify-content-center align-self-center d-flex">
                  Please sign in or sign up to continue with your purchase.
                </h4>

                <Row>
                  <Col sm={6}>
                    <Button href="/users/sign_in" className="btn-block btn-primary">
                      Sign In
                    </Button>
                  </Col>
                  <Col sm={6}>
                    <Button href="/users/sign_up" className="btn-block btn-secondary">
                      Sign Up
                    </Button>
                  </Col>
                </Row>
              </Col>
              <hr />
            </React.Fragment>
          )}
        </Row>
        <Row className="mt-3">
          <Col className="text-center">
            <p className="text-muted"><small>All sales are final. No refunds or exchanges.</small></p>
          </Col>
        </Row>
      </div>

      {serumOrSerumPresets &&
        <SerumUpsells />
      }

      <UpsellProducts />
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  cart_items: state.cart_items,
  cart_errors: state.cart_errors,
  cart_total: getCartTotal(state.cart_items)
})

export default connect(mapStateToProps)(CheckoutCart)