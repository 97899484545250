import React from "react";
import { connect } from "react-redux";
import { Elements, StripeProvider } from "react-stripe-elements";
import StripeCheckout from "./StripeCheckout";
import { PayPalButton } from "react-paypal-button-v2";

import { getCartTotal } from "../actions";
import { Button, Col } from "react-bootstrap";

class PaymentOptions extends React.Component {
  constructor() {
    super();
    this.state = {
      stripeHidden: true,
      paypalError: null,
      paypalErrorMessage: null
    };
    this.paypalOptions = {
      currency: "USD",
      intent: "capture",
      disableFunding: "paylater,credit,card"
    };
  }

  createOrder = async () => {
    try {
      this.setState({ paypalError: null, paypalErrorMessage: null });
      const response = await fetch("/api/external/orders", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cart: this.props.cart_items,
        }),
      });

      const orderData = await response.json();

      if (orderData.id) {
        return orderData.id;
      }

      throw new Error('Failed to create PayPal order');
    } catch (error) {
      this.setState({ paypalError: 'Unable to create PayPal order. Please try again.' });
      console.error(error);
      throw error;
    }
  };

  onApprove = async (data, actions) => {
    try {
      this.setState({ paypalError: null, paypalErrorMessage: null });
      const response = await fetch(`/api/external/orders/${data.orderID}/capture`, {
        method: "POST",
      });

      const orderData = await response.json();

      // Handle instrument declined error
      if (orderData.error === "INSTRUMENT_DECLINED") {
        return actions.restart();
      }

      // Handle capture failed error
      if (orderData.error === "CAPTURE_FAILED") {
        this.setState({
          paypalError: 'Payment failed',
          paypalErrorMessage: orderData.message || 'The payment was declined. Please try a different payment method.'
        });
        return;
      }

      if (orderData.id) {
        window.location.href = `/orders/${orderData.id}/thank_you`;
        return;
      }

      throw new Error(JSON.stringify(orderData));
    } catch (error) {
      let errorMessage = 'There was a problem processing your PayPal payment. Please try again.';

      try {
        const errorData = JSON.parse(error.message);
        if (errorData.message) {
          errorMessage = errorData.message;
        }

        // Handle specific PayPal errors
        if (errorData.error === "CAPTURE_FAILED") {
          errorMessage = errorData.message || 'The payment was declined. Please try a different payment method.';
        }
      } catch (parseError) {
        console.error("Error parsing error message:", parseError);
      }

      this.setState({
        paypalError: 'Payment Error',
        paypalErrorMessage: errorMessage
      });

      console.error("PayPal payment error:", error);
    }
  };

  toggleHidden() {
    this.setState({
      stripeHidden: !this.state.stripeHidden,
    });
  }
  render() {
    return (
      <React.Fragment>
        <Col sm className="d-flex justify-content-center align-self-center flex-column">
          {this.state.paypalError && (
            <div className="alert alert-danger mb-3" role="alert">
              <strong>{this.state.paypalError}:</strong> {this.state.paypalErrorMessage}
            </div>
          )}
          <Col sm className="d-flex justify-content-center align-self-center">
            {this.state.stripeHidden && (
              <Button variant="primary" onClick={this.toggleHidden.bind(this)}>
                Pay {this.props.cart_total} USD with Stripe
                <br />
                <i className="fa fa-2x fa-cc-visa"></i>
                <i className="fa fa-2x fa-cc-mastercard"></i>
                <i className="fa fa-2x fa-cc-amex"></i>
              </Button>
            )}

            {!this.state.stripeHidden && (
              <StripeProvider apiKey={this.props.stripeKey}>
                <Elements>
                  <StripeCheckout />
                </Elements>
              </StripeProvider>
            )}

            {this.state.stripeHidden && (
              <Col sm={5} className="offset-sm-1 d-flex justify-content-center align-self-center">
                <PayPalButton
                  amount={this.props.cart_total}
                  currency="USD"
                  createOrder={this.createOrder}
                  onApprove={this.onApprove}
                  options={{
                    clientId: this.props.paypalClientId,
                    disableFunding: "paylater,credit,card"
                  }}
                  style={{
                    color: "black",
                    layout: "vertical",
                    shape: "rect",
                    label: "paypal",
                    tagline: false
                  }}
                />
              </Col>
            )}
          </Col>
        </Col>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  cart_total: getCartTotal(state.cart_items),
});

export default connect(mapStateToProps)(PaymentOptions);